/** 
 * import : 
 * 
 * @version 0.1 (2021.06.24 : usayama)
*/
import React from 'react'
// conf, action
// components
import LabelHtml from '@components/Typo/LabelHtml'
// containers
// helper
// style, img, elm
// const

/** 
 * const : 
 * 
 * @version 0.1 (2021.06.24 : usayama)
*/
export default function ZerocarbonBannersContainer(props) {

  /** 
   * return : 
   * 
   * @version 0.1 (2021.06.24 : usayama)
   * @version 0.2 (2021.06.29 : usayama) // バナーにリンク追加、image 連番 1始まり、0埋めにする
   */
  return (
  <div className="zero_carbon_action-banners wrap">
    <div className="title title-h-line">
      <h3><LabelHtml label="title.zero_carbon_action" style={{'letter-spacing':'0.15rem'}} /></h3>
      <div className="h-line">
        <span></span>
        <span></span>
      </div>
    </div>
    <div className="container">
      <div className="row">
      {
        Array(8).fill().map((key, i) => {
          const linkNumber = ( '00' + (i + 1) ).slice( -2 )
          const imgPath = require("@assets/images/zero_carbon_action_" + linkNumber + ".png")
          return <div key={'zero_carbon_action' + i} className="col-6 col-md-3 mb-4">
            <a 
              className="zero_carbon_action-banner" 
              href={"https://tsunaichi.jp/brand/zero_carbon_top/#action-" + linkNumber}
              target="_blank"
              rel="noreferrer"
            >
              <img src={imgPath.default} className="w-100 mb-2" alt="" />
              <h4><LabelHtml label={"label.zero_carbon_action_" + i} /></h4>
            </a>
          </div>
        })
      }
      </div>
    </div>
  </div>
  )
}
