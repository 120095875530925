/** 
 * import : 
 * 
 * @version 0.1 (2021.05.17 : usayama_dx)
 * @version 0.2 (2021.06.11 : usayama_dx) // AwesomeSlider に変更 -> 調整に時間を要するのでコメントアウト
 * @version 0.3 (2021.09.29 : usayama) // slideer 実装
 * @version 0.4 (2023.05.07 : usayama) // slideer に img 追加
 * @version 0.5 (2024.08.29 : usayama) // 画像変更
*/
import React from 'react'
import { Carousel } from 'react-responsive-carousel'
// import AwesomeSlider from 'react-awesome-slider'
// conf, action
// components
import LabelHtml from '@components/Typo/LabelHtml'
// containers
// helper
// style, img
import "react-responsive-carousel/lib/styles/carousel.min.css"
const mainImg00 = require("@assets/images/main-img-00.jpg")
const mainImg01 = require("@assets/images/main-img-01.jpg")
const mainImg02 = require("@assets/images/main-img-02.jpg")
const mainImg03 = require("@assets/images/main-img-03.jpg")
const logo = require("@assets/images/logo-shadow.png")
// consts

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.17 : usayama_dx)
*/
export default function MainImageContainer(props) {

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.17 : usayama_dx)
   * @version 0.2 (2021.06.11 : usayama_dx) // slider 削除
   * @version 0.3 (2021.06.30 : usayama) // className= に修正
   * @version 0.4 (2021.09.29, 30 : usayama) // Carousel 実装
   * @version 0.5 (2023.05.07 : usayama) // produced_by 追加, img 追加
   */
  return (
     <div className="main-image">
      <h1><LabelHtml label="title.main_label" /></h1>
      <div className="main-image-logo">
        <img src={logo.default} alt="" />
        <div style={{
          marginTop: 10,
          textShadow: '1px 1px 2px #000, 0 0 1em #000, 0 0 0.2em #000',
          color: '#fff',
          fontWeight: 'bold',
          fontSize: '1.0rem'
        }}><LabelHtml label="title.produced_by" /></div>
      </div>
      <Carousel
        autoPlay={true}
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        infiniteLoop={true}
        stopOnHover={false}
       >
        <div className="main-image-bg" style={{ backgroundImage : `url(${mainImg00.default})` }} />
        <div className="main-image-bg" style={{ backgroundImage : `url(${mainImg01.default})` }} />
        <div className="main-image-bg" style={{ backgroundImage : `url(${mainImg02.default})` }} />
        <div className="main-image-bg" style={{ backgroundImage : `url(${mainImg03.default})` }} />
      </Carousel>
    </div>
  )
}
